import { IconButton } from "@chakra-ui/react";
import { Flex } from "@chakra-ui/layout";
import Translation from "./Translation.js";
import React, { useContext } from "react";
import DarkMode from "./DarkMode";
import { languageContext } from "../context/Language.js";
export default function Header() {
  const { onLanguageChange } = useContext(languageContext);

  return (
    <Flex justifyContent={"flex-end"} p={"4"}>
      <DarkMode />
      <IconButton
        size={"md"}
        alignSelf="flex-end"
        colorScheme={"gray"}
        mr={"4"}
        _focus={{ outline: "none" }}
        icon={<Translation />}
        onClick={() => {
          onLanguageChange();
        }}
      />
    </Flex>
  );
}
