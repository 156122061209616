import React from "react";
import { Box, Text, Link, Center, useColorModeValue } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

export default function BlogCard({ dets }) {
  const bg = useColorModeValue("white", "#0e0e10");

  return (
    <Box>
      <Box>
        <Box
          maxW="sm"
          borderWidth="1px"
          borderRadius="lg"
          boxShadow={"sm"}
          p={"3"}
          bg={bg}
        >
          <Text fontWeight={"bold"}>{dets.title}</Text>
          <Text>{dets.des}</Text>
          <Center mt={"4"}>
            <Link
              _focus={{ outline: "none" }}
              color={"blue.300"}
              href={dets.link}
              isExternal
            >
              <ExternalLinkIcon w={"7"} h={"7"} />
            </Link>
          </Center>
        </Box>
      </Box>
    </Box>
  );
}
