import { Container } from "@chakra-ui/react";
import BlogPost from "./components/BlogPost";
import Contact from "./components/Contact";
import Header from "./components/Header";
import Introduction from "./components/Introduction";
import Projects from "./components/Projects";
import Skills from "./components/Skills";
import { Language } from "./context/Language";
function App() {
  return (
    <Language>
      <Container maxW="container.md">
        <Header />
        <Introduction />
        <Projects />
        <Skills />
        <BlogPost />
        <Contact />
      </Container>
    </Language>
  );
}

export default App;
