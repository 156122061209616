export const blogDetails = [
  {
    title: "MongoDB search with autocomplete using EJS.",
    des: "In this blog post i wrote about how to implement autcomplete and fuzzy search with MongoDB, EJS and NodeJS.",
    img: "https://res.cloudinary.com/dvfihlcxd/image/upload/v1626617484/portfolio/whole_jjxxo6.jpg",
    link: "https://agonstips.hashnode.dev/mongodb-search-with-autocomplete-using-ejs",
  },
  {
    title: "File upload to cloudinary with nodejs and express-fileupload.",
    des: "Uploading file with express-fileupload to cloudinary",
    img: "https://res.cloudinary.com/dvfihlcxd/image/upload/v1626617604/portfolio/image-_1__trijf1.jpg",
    link: "https://agonstips.hashnode.dev/file-upload-to-cloudinary-with-nodejs-and-express-fileupload",
  },
];
