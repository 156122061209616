import React, { useContext, useEffect } from "react";
import { Heading, Box, SimpleGrid } from "@chakra-ui/react";
import { languageContext } from "../context/Language";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import BlogCard from "./BlogCard";
import { blogDetails } from "../util/BlogDetails";
export default function BlogPost() {
  const { lingo } = useContext(languageContext);
  const { ref, inView } = useInView();
  const controls = useAnimation();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  const item = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 50 },
  };
  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={item}
      transition={{ duration: 1, times: [0, 0.2, 1] }}
    >
      <Box mb={"32"} position={"relative"}>
        <Heading> {lingo === "EN" ? "Blog posts" : "文章"}</Heading>

        <SimpleGrid
          columns={[1, 1, 2]}
          spacingX={"60px"}
          spacingY={"20px"}
          mt={"4"}
        >
          {blogDetails.map((post) => (
            <BlogCard dets={post} key={post.title} />
          ))}
        </SimpleGrid>
      </Box>
    </motion.div>
  );
}
