import React, { useContext } from "react";
import { Box, Heading, Text } from "@chakra-ui/react";
import { useClipboard } from "@chakra-ui/react";
import { Tooltip } from "@chakra-ui/react";
import { languageContext } from "../context/Language";
import {
  contactBodyCN,
  contactBodyEN,
  contactCN,
  contactEN,
} from "../util/Text";
export default function Contact() {
  const { lingo } = useContext(languageContext);
  const copyText = lingo === "EN" ? "copy" : "复制";
  const copiedText = lingo === "EN" ? "copied" : "复制的";
  let email = lingo === "EN" ? "abelmasresha6@gmail.com" : "3142757974@qq.com";
  const { onCopy, hasCopied } = useClipboard(email);
  return (
    <Box zIndex={"-100000"} position={"relative"}>
      <Heading>{lingo === "EN" ? contactEN : contactCN}</Heading>

      <Text
        _hover={{ cursor: "pointer" }}
        onClick={onCopy}
        mt={"4"}
        mb={"4"}
        fontSize={"xl"}
        fontWeight={"medium"}
      >
        {lingo === "EN" ? contactBodyEN : contactBodyCN}@ :{" "}
        <Tooltip
          label={hasCopied ? copiedText : copyText}
          aria-label="A tooltip"
        >
          {email}
        </Tooltip>
      </Text>
      <Text fontSize={"xl"} fontWeight={"bold"}></Text>
    </Box>
  );
}
