export const IntroHeaderEN = "Hi, I am Abel";
export const IntroHeaderCN = "你好， 我叫 Abel （贝尔）";
export const IntroParaCN =
  "我现在就读于华南理工大学计算机科学专业，即将于明年毕业。";
export const IntroParaEN = "I am a Junior Software Engineer.";
export const MoreAboutmeEN = "Interested in everything JavaScript and more.";
export const MoreAboutmeCN =
  "我对所有的 JavaScript都感兴趣，目前专注于 reactJs 和 TypeScript的学习。";
export const CurrentlySeekingEN =
  "I am currently looking for a remote software engineering role.";
export const CurrentlySeekingCN =
  "我现在正在找一份初级开发人员岗位的工作，希望能有一个平台提供机会去展示自己。";
export const skillHeadingEN =
  "Some of my skills and Technologies i like to use";
export const skillHeadingCN = "我的一些技能";
export const ProjectsEN =
  "Some of my top projects. I am passionate about building things, especially imagining an idea and making it coming to fruition.";
export const ProjectsCN =
  "我热衷于构建事物，尤其是想象一个想法并使之实现。这里有一些我优秀的项目。";
export const contactEN = "Wanna Connect ?";
export const contactCN = "想联系我吗？";
export const contactBodyCN = "可随时发送电子邮件";
export const contactBodyEN = "Feel free to send an email";
