import React, { createContext, useState } from "react";

export const languageContext = createContext();

export function Language({ children }) {
  let lingo = JSON.parse(localStorage.getItem("language")) || "EN";

  const [language, setLanguage] = useState(lingo);
  localStorage.setItem("language", JSON.stringify(lingo));
  const onLanguageChange = () => {
    let currentLingo = JSON.parse(localStorage.getItem("language"));

    if (currentLingo === "EN") {
      setLanguage("CN");
      localStorage.setItem("language", JSON.stringify("CN"));
    }
    if (currentLingo === "CN") {
      setLanguage("EN");
      localStorage.setItem("language", JSON.stringify("EN"));
    }
  };
  return (
    <languageContext.Provider
      value={{ lingo: language, onLanguageChange: onLanguageChange }}
    >
      {children}
    </languageContext.Provider>
  );
}
