import React, { useContext } from "react";
import { Avatar, Box, Heading, Image, Text } from "@chakra-ui/react";
import { languageContext } from "../context/Language";
import {
  IntroHeaderCN,
  IntroHeaderEN,
  IntroParaCN,
  IntroParaEN,
  CurrentlySeekingCN,
  CurrentlySeekingEN,
  MoreAboutmeCN,
  MoreAboutmeEN,
} from "../util/Text";
import { Breadcrumb, BreadcrumbItem, Link } from "@chakra-ui/react";
import { motion } from "framer-motion";

export default function Introduction() {
  const { lingo } = useContext(languageContext);

  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };
  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={variants}
      transition={{ duration: 2 }}
    >
      <Box mt={"32"} mb={"24"}>
        <Avatar
          src={
            "https://res.cloudinary.com/dvfihlcxd/image/upload/v1625905666/portfolio/me_hlt4s2.jpg"
          }
          size={"xl"}
          name={"Abel owner of this portfolio"}
          mb={"4"}
        />
        <Heading>{lingo === "EN" ? IntroHeaderEN : IntroHeaderCN} 👨🏽‍💻</Heading>
        <Text mt={"4"} fontSize={"2xl"} fontWeight={"bold"}>
          {lingo === "EN" ? IntroParaEN : IntroParaCN}
        </Text>

        <Text mt={"6"} fontSize={"xl"} fontWeight={"normal"}>
          {lingo === "EN" ? MoreAboutmeEN : MoreAboutmeCN}
        </Text>

        <Text mt={"6"} fontSize={"xl"} fontWeight={"normal"}>
          {lingo === "EN" ? CurrentlySeekingEN : CurrentlySeekingCN}
        </Text>
        <Box mt={"6"}>
          <Breadcrumb
            separator=" "
            alignItems={"center"}
            display={"inline-block"}
          >
            <BreadcrumbItem>
              <Link
                href="https://github.com/Agon269"
                isExternal
                _focus={{ outline: "none" }}
              >
                <Image
                  w={"30px"}
                  src="https://res.cloudinary.com/dvfihlcxd/image/upload/v1625942591/github_kosl0p.svg"
                />
              </Link>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <Link
                href="https://www.linkedin.com/in/abel-tadesse-06b505175/"
                isExternal
                _focus={{ outline: "none" }}
              >
                <Image
                  w={"30px"}
                  src="https://res.cloudinary.com/dvfihlcxd/image/upload/v1625942591/linkedin_uocczl.svg"
                />
              </Link>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage>
              <Link
                href="https://twitter.com/Agon_42"
                isExternal
                _focus={{ outline: "none" }}
              >
                <Image
                  w={"30px"}
                  src="https://res.cloudinary.com/dvfihlcxd/image/upload/v1625942592/twitter_beob2h.svg"
                />
              </Link>
            </BreadcrumbItem>
          </Breadcrumb>
        </Box>
      </Box>
    </motion.div>
  );
}
