import React, { useEffect } from "react";
import { Image } from "@chakra-ui/react";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
export default function Skill({ skill }) {
  const { ref, inView } = useInView();
  const controls = useAnimation();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  const item = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: -100 },
  };

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={item}
      transition={{ duration: 1, times: [0, 0.2, 1] }}
    >
      <Image src={skill.img} alt={skill.alt} />
    </motion.div>
  );
}
