export const ProjectDetails = [
  {
    title: "Twirl",
    des: "Twirl was one of the more complicated projects I worked on, it is a site where users can upload their coding problems and other people can help solve but using videos. Some design ideas were taken from Twitch and Youtube. I used Redux state management for this project.",
    desCN:
      "Twirl是我工作过比较复杂的项目之一。这是用户可以上传他们的编码问题和其他人可以帮助解决这些问题的网站，但是这些过程得使用视频。这个项目一些设计灵感主要来自Twitch和Youtube。对于这个项目，我使用了Redux状态管理。",
    stack: [],
    left: "-20px",
    color: "purple.600",
    top: "70px",
    justify: "flex-end",
    link: "https://twirl-845f4.web.app/",
    img: "https://res.cloudinary.com/dvfihlcxd/image/upload/v1628425364/screencapture-twirl-845f4-web-app-2021-08-08-20_20_57_wje2mf.png",
    git: "https://github.com/Agon269/share-solution",
  },

  {
    title: "Share Files",
    des: "It helps users share files anonymously with random emails. I made this website using react and firebase. learned file uploading and database management with firebase. also used react context to handle user authorization. this was a fun project because I learned to deploy react apps on firebase.",
    desCN:
      "它帮助用户通过随机电子邮件匿名共享文件。我用react与firebase创建了这个网站，和使用firebase的文件上传和数据库管理，此外还使用了react context来处理用户授权。这是一个有趣的项目，因此我学会了在firebase上运用react应用程序。主要想法之一是匿名共享文件，这在当今很难做到",
    left: "270px",
    justify: "flex-start",
    color: "teal",
    top: "70px",
    link: "https://share-with-me-6d766.web.app/",
    img: "https://res.cloudinary.com/dvfihlcxd/image/upload/v1628445453/screencapture-share-with-me-6d766-web-app-2021-08-09-01_57_22_evcwiu.png",
    git: "https://github.com/Agon269/share-files",
  },
  {
    title: "Nasa Api",
    des: "I utilized Nasa's public API to work with some fun data sets. on this project, mostly learned heavy state management and react routing. It has some fun features like checking the weather on Mars and finding satellite images based on coordinates or browser location.",
    desCN:
      "我真没想好这个项目的名字，但是我使用了美国国家航空航天局的公共API来处理了一些有趣的数据集。在这个项目中，我主要学习了繁重的状态管理和React Routing。它有一些有趣的功能，比如查看火星的天气和根据坐标或浏览器位置查找卫星图像。",

    justify: "flex-end",
    left: "-20px",
    color: "black",
    top: "70px",
    link: "https://nasapi-usage.netlify.app/",
    img: "https://res.cloudinary.com/dvfihlcxd/image/upload/v1628446304/screencapture-nasapi-usage-netlify-app-2021-08-09-01_58_57_j6sset.png",
    git: "https://github.com/Agon269/nasapi",
  },

  {
    title: "Recepie Recommendation",
    des: "This is the first ReactJs project that I made after learning the basics of ReactJs. I learned the basics of ReactJs hooks, form submission, requesting data from an API with authentication, and receiving and managing API data using Axios.",
    desCN:
      "这是我在学习了 ReactJs 基础知识之后做的第一个 ReactJs 项目。 我学习了 ReactJs hooks、form submission、通过身份验证从API请求数据和使用Axios接收和管理API数据的基础知识。提交表单时有点复杂，因为我必须在另一个子组件中处理一个组件的状态。",
    left: "270px",
    justify: "flex-start",
    color: "blue.500",
    top: "90px",
    link: "https://food-recommend.netlify.app/",
    img: "https://res.cloudinary.com/dvfihlcxd/image/upload/v1628445935/screencapture-food-recommend-netlify-app-2021-08-09-02_00_27_jg9ufb.png",
    git: "https://github.com/Agon269/Food-Recommender",
  },
  {
    title: "Artsy",
    des: "One of the first solo projects that I made with NodeJs. It was a simple e-commerce site that people can upload their art and other people can buy it, although its payment method is not fully complete all other functions work seamlessly.",
    desCN:
      "这是我用 NodeJs 做的第一个个人项目。它是一个简单的电子商务网站，人们可以在这个网站上上传他们的艺术品，其他人也可以购买它们，尽管网站的付款功能不完善，但所有其他功能都可以无缝地协同工作。它不是一个完全完整的项目，但它具有 CRUD 软件的所有功能。",
    justify: "flex-end",
    left: "-20px",
    color: "pink.400",
    top: "90px",
    link: "https://mysterious-tor-13487.herokuapp.com/",
    img: "https://res.cloudinary.com/dvfihlcxd/image/upload/v1628446066/screencapture-mysterious-tor-13487-herokuapp-2021-08-09-02_01_06_lnd6ho.png",
    git: "https://github.com/Agon269/Artsy",
  },
];
