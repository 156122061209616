import React, { useContext, useEffect } from "react";
import { Heading, Box, Text } from "@chakra-ui/react";

import { languageContext } from "../context/Language";
import { ProjectsCN, ProjectsEN } from "../util/Text";
import { ProjectDetails } from "../util/ProjectsDetails";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import ProjectCardTrial from "./ProjectCardTrial";

export default function Projects() {
  const { lingo } = useContext(languageContext);
  const { ref, inView } = useInView();
  const controls = useAnimation();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  const item = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 50 },
  };

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={item}
      transition={{ duration: 1, times: [0, 0.2, 1] }}
    >
      <Box mb={"28"} position={"relative"}>
        <Box mb={{ base: "4", lg: "24" }}>
          <Heading> {lingo === "EN" ? "Projects" : "项目"}</Heading>
          <Text fontSize={"xl"} mt={"6"} mb={"6"}>
            {lingo === "EN" ? ProjectsEN : ProjectsCN}
          </Text>
        </Box>

        {ProjectDetails.map((project) => (
          <ProjectCardTrial project={project} key={project.title} />
        ))}
      </Box>
    </motion.div>
  );
}
