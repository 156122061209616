export const imgs = [
  {
    alt: "HTML",
    img: "https://res.cloudinary.com/dvfihlcxd/image/upload/v1625906251/portfolio/w3_html5-ar21_x3h8ey.svg",
  },
  {
    alt: "css",
    img: "https://res.cloudinary.com/dvfihlcxd/image/upload/v1625906336/portfolio/netlifyapp_watercss-ar21_qsmjzf.svg",
  },
  {
    alt: "Sass",
    img: "https://res.cloudinary.com/dvfihlcxd/image/upload/v1628448057/sass-lang-ar21_b4xotx.svg",
  },
  {
    alt: "JavaScript",
    img: "https://res.cloudinary.com/dvfihlcxd/image/upload/v1625905747/portfolio/javascript-ar21_pkvcpp.svg",
  },
  {
    alt: "ReactJs",
    img: "https://res.cloudinary.com/dvfihlcxd/image/upload/v1625905747/portfolio/reactjs-ar21_udrdto.svg",
  },
  {
    alt: "Redux",
    img: "https://res.cloudinary.com/dvfihlcxd/image/upload/v1625907775/portfolio/redux_1_imywmy.svg",
  },
  {
    alt: "tailwind css",
    img: "https://res.cloudinary.com/dvfihlcxd/image/upload/v1626619772/portfolio/tailwindcss-logotype.128b6e12eb85d013bc9f80a917f57efe_zaoxht.svg",
  },
  {
    alt: "firebase",
    img: "https://res.cloudinary.com/dvfihlcxd/image/upload/v1625905747/portfolio/firebase-ar21_j9dqm1.svg",
  },
  {
    alt: "NodeJS",
    img: "https://res.cloudinary.com/dvfihlcxd/image/upload/v1625905746/portfolio/nodejs-ar21_admljf.svg",
  },
  {
    alt: "MongoDB",
    img: "https://res.cloudinary.com/dvfihlcxd/image/upload/v1625905748/portfolio/mongodb-ar21_puoju7.svg",
  },
  {
    alt: "git",
    img: "https://res.cloudinary.com/dvfihlcxd/image/upload/v1626623637/portfolio/Git_icon_mq84jz.svg",
  },

  {
    alt: "Jest",
    img: "https://res.cloudinary.com/dvfihlcxd/image/upload/v1626623477/portfolio/jest-seeklogo.com_j2plpw.svg",
  },
];
