import React, { useContext } from "react";
import { Heading, Box, SimpleGrid } from "@chakra-ui/react";
import { skillHeadingCN, skillHeadingEN } from "../util/Text";
import { languageContext } from "../context/Language";
import { motion } from "framer-motion";
import { imgs } from "../util/SkillImg";
import Skill from "./Skill";
export default function Skills() {
  const { lingo } = useContext(languageContext);

  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };
  return (
    <Box mb={"36"}>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={variants}
        transition={{ duration: 2 }}
      >
        <Heading>{lingo === "EN" ? skillHeadingEN : skillHeadingCN}</Heading>
      </motion.div>
      <SimpleGrid
        columns={[2, 2, 4]}
        spacingX={"30px"}
        spacingY={"20px"}
        mt={"6"}
        alignItems={"center"}
      >
        {imgs.map((img) => (
          <Skill skill={img} key={img.alt} />
        ))}
      </SimpleGrid>
    </Box>
  );
}
