import React, { useContext } from "react";
import {
  Box,
  Image,
  Text,
  useColorModeValue,
  Center,
  Link,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { languageContext } from "../context/Language";

const ProjectCardTrial = ({ project }) => {
  const bg = useColorModeValue("white", "#0e0e10");
  const { lingo } = useContext(languageContext);
  return (
    <Box
      position={{ sm: "relative" }}
      pb={{ base: "4", lg: "28" }}
      pt="4"
      display={{ lg: "flex" }}
      justifyContent={project.justify}
    >
      <Box maxW="550px" display={{ lg: "block", base: "none" }}>
        <Image src={project.img} alt="Twirl website logo" borderRadius="lg" />
      </Box>
      <Box
        backgroundColor="white"
        p="3"
        borderRadius="xl"
        boxShadow={"md"}
        borderWidth="1px"
        position={{ lg: "absolute" }}
        maxW="400px"
        top={project.top}
        left={project.left}
        bg={bg}
      >
        <Text fontWeight="bold" color={project.color} fontSize="2xl">
          {project.title}
        </Text>
        <Text>{lingo === "EN" ? project.des : project.desCN}</Text>

        <Center mt={"4"} zIndex={"1000000"}>
          <Link
            _focus={{ outline: "none" }}
            color={"blue.300"}
            href={project.git}
            mr={"6"}
            isExternal
          >
            <Image
              w={"30px"}
              src="https://res.cloudinary.com/dvfihlcxd/image/upload/v1625942591/github_kosl0p.svg"
              alt={"github logo"}
            />
          </Link>
          <Link
            _focus={{ outline: "none" }}
            color={"blue.300"}
            href={project.link}
            isExternal
          >
            <ExternalLinkIcon w={"7"} h={"7"} />
          </Link>
        </Center>
      </Box>
    </Box>
  );
};
export default ProjectCardTrial;
